import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import Footer from '../../components/shared/Footer/Footer';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import BackToTraceabilityButton from "../../components/shared/ButtonBackTo/BackToTraceabilityButton";
import MainboardCuttingArea from "../../components/sections/MainboardCuttingArea/MainboardCuttingArea";
import SideboardCuttingArea from "../../components/sections/SideboardCuttingArea/SideboardCuttingArea";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";

const AtTheSawmillPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.TraceabilityAtTheSawmill.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={routes.TraceabilityAtTheSawmill.pageTitle}
                parentTitles={[routes.Traceability.pageTitle]}
                parentRoutes={[routes.Traceability]}
            />

            <SideboardCuttingArea/>

            <MainboardCuttingArea/>

            <BackToTraceabilityButton/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default AtTheSawmillPage;

